<template>
  <base-section id="k-d-a-careers-header" class="white" space="0">
    <v-container fluid class="pa-0">
      <v-row
        align="center"
        :style="{
          gap: g_bBaseBr ? '0' : '40px',
          padding: g_bLowerBr ? '0 0' : g_bBaseBr ? `24px 24px` : `160px 74px`
        }"
        no-gutters
      >
        <v-col cols="12" md="auto">
          <base-img
            :src="require('@/assets/photos/careers-header.png')"
            :max-height="g_bLowestBr ? 240 : 320"
            :contain="!g_bLowerBr"
          />
        </v-col>
        <v-col class="pa-7 pa-md-15">
          <h1 :class="`kda-ts-${g_bLowestBr ? '28' : '46'}pt wt-extrabold`">{{ m_strTitle }}</h1>

          <component
            :is="m_objDesc.strComponent"
            :class="`kda-ts-${g_bLowestBr ? '12' : '16'}pt nunito ln-150 main-bw-300--text`"
            v-html="m_objDesc.htmlText"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDACareersHeader',

  mixins: [BaseBreakpoint],

  computed: {
    m_strTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.header.title');
    },
    m_objDesc() {
      return {
        htmlText: this.$vuetify.lang.t('$vuetify.kda.careers.header.desc'),
        strComponent: 'p'
      };
    }
  }
};
</script>
